#sidebar_road {
    background-color: rgb(240, 248, 255, 0.9);
    height: 100%;
    margin: 10px;
    padding: 10px;
}

#sidebar_road_card {
    background-color: rgb(240, 248, 255, 0.9);
    margin: 10px;
    padding: 10px;
}