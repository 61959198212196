/*
.mapbox-gl-draw_ctrl-draw-btn {
    border-color: rgba(0,0,0,0.9);
    color: rgba(255,255,255,0.5);
}
*/

.mapbox-gl-draw_line {
    background-image: url(data:image/svg+xml;utf8,%3Csvg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\">%3Cpath d=\"m13.5 3.5c-1.4 0-2.5 1.1-2.5 2.5 0 .3 0 .6.2.9l-3.8 3.8c-.3-.1-.6-.2-.9-.2-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5c0-.3 0-.6-.2-.9l3.8-3.8c.3.1.6.2.9.2 1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5z\"/>%3C/svg>);
    background-repeat:  no-repeat;
    background-position:center center;
    background-size:contain;
    width:40px !important;
    height:40px !important;
}

.mapbox-gl-draw_trash {
    background-image: url(data:image/svg+xml;utf8,%3Csvg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\">%3Cpath d=\"M10,3.4 c-0.8,0-1.5,0.5-1.8,1.2H5l-1,1v1h12v-1l-1-1h-3.2C11.5,3.9,10.8,3.4,10,3.4z M5,8v7c0,1,1,2,2,2h6c1,0,2-1,2-2V8h-2v5.5h-1.5V8h-3 v5.5H7V8H5z\"/>%3C/svg>);
    background-repeat:  no-repeat;
    background-position:center center;
    background-size:contain;
    width: 40px !important;
    height:40px !important;
}

.map-container-road {
    width: 100% !important;
    height: 100dvh !important;

}

/*
.result-window {
    position: fixed;
    bottom: 30px;
    left: 10px;
    background-color: rgba(255, 255, 255, 1.0);
    padding: 8px;
}
*/

.result-base {
    width: 100%;
    border-collapse: collapse;
    border-color: black;
}

.result-item th {
    background-color: rgba(200, 200, 200, 0.9);
    text-align: center;
    vertical-align: top;
}

.result-item td {
    text-align: center;
    vertical-align: top;
}

.result-item th, .result-item td {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 1.3em;
}

.result-item_link {
    color: #000;
    text-decoration:underline;
    text-decoration-color:#000;
}