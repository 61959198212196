@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css');

/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
#root {
  width: 100%;
  /*height: calc(100vh - 3rem);*/
  height: 100vh !important;
  /*overflow: hidden;*/
  position: relative;
}
.header-nav {
  background-color: rgb(23, 162, 184);
}
.map-container {
  width: 100%;
  height: calc(100vh - 3rem);
}
.mapboxgl-canvas {
  cursor: pointer;
}
.mapboxgl-ctrl-top-left {
  top: 10px !important;
}
.mapboxgl-ctrl-top-right {
  top: 10px !important;
}

.mapboxgl-ctrl-bottom-left {
  bottom: 20px !important;
}
.guide {
  position: relative;
  z-index: 13;
  background-color: rgb(255, 255, 255, 0.8);
  width: 100%;
  padding: 0 .75rem;
}
.act2-dialog label {
  font-size: 0.8rem;
}
.half-circle {
  border-radius: 15px;
  padding-top: .10rem !important;
  padding-bottom: .10rem !important;
}
.table td , .table.th {
  padding: .3rem .2rem !important;
}
.table-bordered {
  font-size: 0.8rem !important;
}
#sidebar {
  background-color: rgb(240, 248, 255, 0.9);
  position: absolute;
  top: 63px;
  right: 0;
  padding-top: 60px !important;
  height: 100%;
  width: 340px;
  z-index: 11;
  overflow: auto;
  scrollbar-color: #87ceeb #4DC4FF;
}
#loading {
  background-color: rgb(200, 200, 200, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 100px !important;
  height: 100%;
  width: 100%;
  z-index: 15;
  color: #fff;
}
#loading div {
  background-color: rgb(100, 100, 100, 0.5);
  width: 30%;
  margin: 0 auto;
}
.recharts-default-legend {
  font-size: 10px;
}
.recharts-legend-item-text {
  color: #333 !important;
}
.map-dialog {
  font-size: 14px !important;
}
.react-draggable {
  background-color: #FFF;
  border: 1px solid #003366;
  z-index: 13;
  padding: 10px;
  cursor: move;
  font-size: 10px;
}
.react-draggable ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.popup-item th {
  background-color: rgba(200, 200, 200, 0.9);
}
.popup-item th, .popup-item td {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
}
#footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #003366;
  color: #fff;
  font-size: 10px;
  text-align: center;
  z-index: 12;
}

